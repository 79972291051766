import React from "react"

import { Layout, Logo, SEO } from "../components"
import { Intro, Tagline } from "../ui"

const IndexPage = () => (
  <Layout>
    <SEO title="Hello!" keywords={[`books`, `children's`, `kids`, `board`]} />
    <Logo />
    <Intro>We make little books</Intro>
    <Tagline>
      We create touching and delightful stories that are personal to you and
      your family
    </Tagline>
  </Layout>
)

export default IndexPage
